var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "common_tree_handle" },
    [
      _vm.titleShow
        ? _c(
            "el-header",
            {
              ref: "elHeader",
              staticStyle: {
                "text-align": "right",
                "font-size": "12px",
                height: "auto",
                "min-height": "46px",
                "background-color": "#ffffff",
                padding: "0 12px",
                display: "flex",
                "align-items": "center",
              },
            },
            [
              _c(
                "el-row",
                {
                  staticStyle: {
                    display: "flex",
                    "align-items": "center",
                    width: "100%",
                    "line-height": "45px",
                    "border-bottom": "1px solid #cccccc",
                  },
                },
                [
                  _c(
                    "el-col",
                    {
                      staticStyle: {
                        float: "left",
                        display: "flex",
                        "align-items": "center",
                      },
                      attrs: { span: "18" },
                    },
                    [
                      _c(
                        "el-tooltip",
                        {
                          attrs: {
                            effect: "dark",
                            content:
                              _vm.treeTitle ||
                              this.$t("cip.cmn.title.typeTitle"),
                            placement: "top-start",
                          },
                        },
                        [
                          _c(
                            "label",
                            {
                              staticStyle: {
                                "font-size": "14px",
                                "text-align": "left",
                                "white-space": "nowrap",
                                overflow: "hidden",
                                "text-overflow": "ellipsis",
                              },
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.treeTitle === ""
                                    ? _vm.$t("cip.cmn.title.typeTitle")
                                    : _vm.treeTitle
                                )
                              ),
                            ]
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                  _vm.isShowdig
                    ? _c(
                        "el-col",
                        { attrs: { span: "6" } },
                        [
                          [
                            _c(
                              "el-popover",
                              {
                                attrs: { width: "40" },
                                model: {
                                  value: _vm.visible,
                                  callback: function ($$v) {
                                    _vm.visible = $$v
                                  },
                                  expression: "visible",
                                },
                              },
                              [
                                _c(
                                  "p",
                                  {
                                    staticClass: "popv_p",
                                    on: {
                                      click: function ($event) {
                                        return _vm.add()
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(_vm.$t("cip.cmn.btn.addBtn")) +
                                        "\n            "
                                    ),
                                  ]
                                ),
                                _c(
                                  "p",
                                  {
                                    staticClass: "popv_p",
                                    on: {
                                      click: function ($event) {
                                        return _vm.edit()
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(_vm.$t("cip.cmn.btn.editBtn")) +
                                        "\n            "
                                    ),
                                  ]
                                ),
                                _c(
                                  "p",
                                  {
                                    staticClass: "popv_p",
                                    on: {
                                      click: function ($event) {
                                        return _vm.getdelete()
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(_vm.$t("cip.cmn.btn.delBtn")) +
                                        "\n            "
                                    ),
                                  ]
                                ),
                                _c(
                                  "el-button",
                                  {
                                    attrs: { slot: "reference", type: "text" },
                                    slot: "reference",
                                  },
                                  [
                                    _c("i", {
                                      staticClass: "el-icon-s-operation",
                                      staticStyle: {
                                        "font-size": "18px",
                                        color: "black",
                                      },
                                    }),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ],
                        ],
                        2
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm.isShowSearchInput
        ? _c(
            "el-row",
            { staticStyle: { padding: "14px 12px", background: "#ffffff" } },
            [
              _c("el-input", {
                staticClass: "searchInput",
                attrs: {
                  size: "small",
                  placeholder: _vm.$t("cip.cmn.msg.warning.filterateWarning"),
                },
                model: {
                  value: _vm.filterText,
                  callback: function ($$v) {
                    _vm.filterText = $$v
                  },
                  expression: "filterText",
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm.isShowHeadButton
        ? _c(
            "el-row",
            {
              staticStyle: {
                padding: "14px 12px",
                background: "#ffffff",
                "text-align": "center",
              },
            },
            [
              _c(
                "el-upload",
                {
                  staticClass: "upload-demo",
                  attrs: {
                    action:
                      "/api/sinoma-resource/oss/endpoint/put-file-attach-name-biz/km",
                    multiple: "",
                    limit: 1,
                    "show-file-list": false,
                    "on-success": _vm.handleAvatarSuccess,
                    "on-exceed": _vm.handleExceed,
                  },
                },
                [
                  _c(
                    "el-button",
                    {
                      attrs: {
                        size: "small",
                        type: "primary",
                        icon: "el-icon-upload",
                      },
                    },
                    [_vm._v("点击上传")]
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _c("el-tree", {
        directives: [
          {
            name: "loading",
            rawName: "v-loading",
            value: _vm.loading,
            expression: "loading",
          },
        ],
        ref: "commonTree",
        staticStyle: {
          "font-size": "12px",
          "border-radius": "4px",
          padding: "0 5px 0 12px",
        },
        attrs: {
          data: _vm.treeChangeData,
          props: _vm.defaultProps,
          "default-expand-all": _vm.treeExpand,
          "highlight-current": true,
          "show-checkbox": _vm.showCheckbox,
          "check-strictly": _vm.checkStrictly,
          "node-key": _vm.nodeKey,
          "filter-node-method": _vm.filterNode,
        },
        on: {
          "node-click": _vm.nodeClick,
          "check-change": _vm.handleCheckChange,
        },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function ({ node, data }) {
              return _c("span", { staticStyle: { display: "flex" } }, [
                data.children
                  ? _c("i", {
                      staticClass: "el-icon-folder",
                      staticStyle: { "line-height": "28px" },
                    })
                  : _c("i", {
                      staticClass: "el-icon-document",
                      staticStyle: { "line-height": "28px" },
                    }),
                _c(
                  "span",
                  {
                    staticStyle: {
                      "margin-left": "10px",
                      "white-space": "nowrap",
                      overflow: "hidden",
                      "text-overflow": "ellipsis",
                      width: "200px",
                      display: "block",
                    },
                  },
                  [_vm._v(_vm._s(node.label))]
                ),
              ])
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }